import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="850" height="240" viewBox="0 0 85 24">
    <title>Loader Logo</title>
    <g>
      <g id="Fedspeak">
        <path
          d="M 12.9 21.952 l 0 3.995 l -2.159 0 l 0 -10.438 l 6.953 0 l 0 1.904 l -4.794 0 l 0 2.652 l 4.437 0 l 0 1.887 l -4.437 0 Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M23.36,23.465h1.955A3.031,3.031,0,0,1,24.232,25.412a3.535,3.535,0,0,1-2.287.705,3.476,3.476,0,0,1-2.669-1.1,4.038,4.038,0,0,1-1.02-2.865,4.074,4.074,0,0,1,1.028-2.847,3.4,3.4,0,0,1,2.627-1.13,3.212,3.212,0,0,1,2.541,1.028,3.991,3.991,0,0,1,.91,2.745V22.6H20.3a2.1,2.1,0,0,0,.493,1.334,1.49,1.49,0,0,0,1.156.484A1.278,1.278,0,0,0,23.36,23.465ZM21.932,19.9a1.523,1.523,0,0,0-1.581,1.427H23.292a1.551,1.551,0,0,0-.374-1.028A1.252,1.252,0,0,0,21.932,19.9Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M31.528,25.947v-.629a2.218,2.218,0,0,1-.8.561,2.654,2.654,0,0,1-1.106.238,3.088,3.088,0,0,1-2.524-1.131,4.307,4.307,0,0,1-.927-2.83,4.347,4.347,0,0,1,.927-2.839,3.082,3.082,0,0,1,2.524-1.138,2.669,2.669,0,0,1,1.106.237,2.219,2.219,0,0,1,.8.562V14.9h2.125v11.05Zm0-2.55V20.9a1.523,1.523,0,0,0-.578-.637,1.566,1.566,0,0,0-.849-.246,1.626,1.626,0,0,0-1.327.6,2.351,2.351,0,0,0-.493,1.538,2.321,2.321,0,0,0,.493,1.531,1.636,1.636,0,0,0,1.327.594,1.555,1.555,0,0,0,.858-.246A1.538,1.538,0,0,0,31.528,23.4Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M34.483,23.687h2.09a1.178,1.178,0,0,0,1.276.833q1.071,0,1.071-.749a.483.483,0,0,0-.179-.4,1.544,1.544,0,0,0-.587-.247l-1.529-.34q-1.973-.459-1.972-2.141a2.167,2.167,0,0,1,.867-1.777,3.363,3.363,0,0,1,2.159-.688,3.561,3.561,0,0,1,2.219.62,2.7,2.7,0,0,1,1.01,1.725h-2a1.1,1.1,0,0,0-1.19-.748,1.3,1.3,0,0,0-.706.187.591.591,0,0,0-.3.527q0,.425.68.6l1.564.357q2.022.494,2.023,2.21a2.139,2.139,0,0,1-.875,1.8,3.6,3.6,0,0,1-2.237.663,3.908,3.908,0,0,1-2.311-.638A2.547,2.547,0,0,1,34.483,23.687Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M 43.883 25.148 l 0 3.434 l -2.108 0 l 0 -10.404 l 2.108 0 l 0 0.629 a 1.701 1.701 0 0 1 0.287 -0.266 q 0.216 -0.161 0.296 -0.134 a 2.754 2.754 0 0 1 0.008 -0.004 a 2.655 2.655 0 0 1 1.097 -0.234 a 3.583 3.583 0 0 1 1.168 0.182 a 2.985 2.985 0 0 1 1.365 0.966 a 4.13 4.13 0 0 1 0.89 2.119 a 5.485 5.485 0 0 1 0.045 0.711 q 0 1.683 -0.935 2.822 q -0.935 1.139 -2.533 1.139 q -0.578 0 -1.105 -0.238 a 3.024 3.024 0 0 1 -0.359 -0.19 q -0.27 -0.169 -0.44 -0.371 z m 0 -4.42 l 0 2.499 a 1.51 1.51 0 0 0 0.53 0.606 a 1.815 1.815 0 0 0 0.048 0.032 q 0.391 0.246 0.86 0.246 a 1.878 1.878 0 0 0 0.621 -0.098 a 1.577 1.577 0 0 0 0.705 -0.497 a 2.093 2.093 0 0 0 0.435 -0.927 a 2.969 2.969 0 0 0 0.058 -0.603 a 2.909 2.909 0 0 0 -0.078 -0.692 a 2.11 2.11 0 0 0 -0.415 -0.846 q -0.493 -0.604 -1.326 -0.604 q -0.459 0 -0.841 0.247 q -0.383 0.247 -0.587 0.638 Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M54.829,23.465h1.955A3.031,3.031,0,0,1,55.701,25.412a3.535,3.535,0,0,1-2.287.705,3.476,3.476,0,0,1-2.669-1.1,4.038,4.038,0,0,1-1.02-2.865,4.074,4.074,0,0,1,1.028-2.847,3.4,3.4,0,0,1,2.627-1.13,3.212,3.212,0,0,1,2.541,1.028,3.991,3.991,0,0,1,.91,2.745V22.6H51.769a2.1,2.1,0,0,0,.493,1.334,1.49,1.49,0,0,0,1.156.484A1.278,1.278,0,0,0,54.829,23.465ZM53.401,19.9a1.523,1.523,0,0,0-1.581,1.427H54.761a1.551,1.551,0,0,0-.374-1.028A1.252,1.252,0,0,0,53.401,19.9Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M 64.1 21.102 l 0 4.845 l -2.023 0 l 0 -0.408 a 0.916 0.916 0 0 1 -0.154 0.121 q -0.164 0.105 -0.422 0.2 a 4.107 4.107 0 0 1 -0.155 0.053 a 3.581 3.581 0 0 1 -0.974 0.167 a 4.078 4.078 0 0 1 -0.148 0.003 q -1.207 0 -2.014 -0.663 a 2.162 2.162 0 0 1 -0.8 -1.584 a 3.027 3.027 0 0 1 -0.008 -0.218 a 2.203 2.203 0 0 1 0.234 -1.021 a 2.274 2.274 0 0 1 0.608 -0.73 a 3.025 3.025 0 0 1 1.456 -0.631 a 4.103 4.103 0 0 1 0.643 -0.049 a 3.67 3.67 0 0 1 0.725 0.075 a 4.328 4.328 0 0 1 0.261 0.061 q 0.51 0.136 0.748 0.27 l 0 -0.561 a 1.355 1.355 0 0 0 -0.058 -0.405 a 1.103 1.103 0 0 0 -0.256 -0.436 a 1.002 1.002 0 0 0 -0.448 -0.269 q -0.19 -0.012 -0.39 -0.024 a 2.087 2.087 0 0 0 -0.13 -0.004 a 1.54 1.54 0 0 0 -0.432 0.056 q -0.487 0.143 -0.684 0.642 l -0.006 0.016 l -2.006 0 q 0.17 -1.054 1.046 -1.742 a 3.194 3.194 0 0 1 1.565 -0.65 a 4.245 4.245 0 0 1 0.585 -0.039 q 1.167 0 1.934 0.46 a 2.577 2.577 0 0 1 0.42 0.314 a 2.535 2.535 0 0 1 0.772 1.404 Z M 62.077 23.873 l 0 -0.544 q -0.132 -0.298 -0.499 -0.475 a 1.595 1.595 0 0 0 -0.02 -0.009 a 1.909 1.909 0 0 0 -0.612 -0.166 a 2.356 2.356 0 0 0 -0.246 -0.013 a 2.093 2.093 0 0 0 -0.478 0.052 a 1.628 1.628 0 0 0 -0.466 0.186 q -0.372 0.222 -0.397 0.635 a 1.005 1.005 0 0 0 -0.002 0.062 q 0 0.424 0.334 0.661 a 1.013 1.013 0 0 0 0.057 0.038 a 1.588 1.588 0 0 0 0.524 0.199 a 2.217 2.217 0 0 0 0.428 0.039 a 2.187 2.187 0 0 0 0.506 -0.056 a 1.83 1.83 0 0 0 0.353 -0.122 a 1.317 1.317 0 0 0 0.243 -0.145 q 0.188 -0.154 0.275 -0.35 Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
        <path
          d="M 72.345 25.947 l -2.499 0 l -2.227 -3.06 l -0.612 0.663 l 0 2.397 l -2.108 0 l 0 -11.05 l 2.108 0 l 0 6.273 l 2.601 -2.822 l 2.618 0 l -3.077 3.23 l 3.196 4.369 Z"
          transform="translate(0.581 -8.872)"
          fill="#64ffda"
        />
      </g>
      <path
        d="M.16,23.661l80.68-5.322"
        transform="translate(0.581 -8.872)"
        fill="none"
        stroke="#e6f1ff"
        strokeMiterlimit="10"
        strokeWidth="18"
        opacity="0.45"
      />
    </g>
  </svg>
);

export default IconLoader;
