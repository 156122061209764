import React from 'react';

const IconFolder = () => (
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>pic_line</title>
    <g id="A-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="File" transform="translate(-912.000000, 0.000000)" fill-rule="nonzero">
        <g id="pic_line" transform="translate(912.000000, 0.000000)">
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">

          </path>
          <path d="M20,3 C21.0543909,3 21.9181678,3.81587733 21.9945144,4.85073759 L22,5 L22,19 C22,20.0543909 21.18415,20.9181678 20.1492661,20.9945144 L20,21 L4,21 C2.94563773,21 2.08183483,20.18415 2.00548573,19.1492661 L2,19 L2,5 C2,3.94563773 2.81587733,3.08183483 3.85073759,3.00548573 L4,3 L20,3 Z M9.87852,12.0503 L4.22166,17.7071 C4.15418,17.7746 4.07946,17.8304 4,17.8746 L4,19 L20,19 L20,17.8747 C19.9204,17.8305 19.8456,17.7747 19.778,17.7071 L16.9496,14.8787 L16.2425,15.5858 L16.4496,15.7929 C16.8401,16.1834 16.8401,16.8166 16.4496,17.2071 C16.0591,17.5976 15.4259,17.5976 15.0354,17.2071 L9.87852,12.0503 Z M20,5 L4,5 L4,15.1003 L8.99463,10.1057 C9.450246,9.65009333 10.1700264,9.61971956 10.6608969,10.0145787 L10.7624,10.1057 L14.8283,14.1716 L16.0657,12.9341 C16.5213533,12.47854 17.2411276,12.4481693 17.731997,12.842988 L17.8335,12.9341 L20,15.1007 L20,5 Z M15.5,7 C16.3284,7 17,7.67157 17,8.5 C17,9.32843 16.3284,10 15.5,10 C14.6716,10 14,9.32843 14,8.5 C14,7.67157 14.6716,7 15.5,7 Z" id="B" fill="#64ffda">

          </path>
        </g>
      </g>
    </g>
  </svg>
);

export default IconFolder;
